
import { splitThousands } from '@@/common/assets/js/utils/numbers-utils';

import TagList from 'portal/components/common/TagList.vue';

const FROM_TEXT = 'от';
const TO_TEXT = 'до';
const EXPERIENCE_TEXT = 'Опыт';
const NO_EXPERIENCE_LABEL = 'Нет опыта';
const NO_SALARY_TEXT='з/п не указана';

export default {
    name: 'ContentCard',

    components: {
        TagList,
    },

    props: {
        position: {
            type: String,
            default: '',
        },

        experience: {
            type: String,
            default: '',
        },

        company: {
            type: String,
            default: '',
        },

        city: {
            type: String,
            default: '',
        },

        currency: {
            type: String,
            default: '',
        },

        incomeFrom: {
            type: Number,
            default: 0,
        },

        incomeTo: {
            type: Number,
            default: 0,
        },

        linkTo: {
            type: String,
            default: '/job/vacancy/1',
        },
    },

    computed: {
        salary() {
            if (!this.incomeTo && !this.incomeFrom) {
                return NO_SALARY_TEXT;
            }

            if (!this.incomeFrom) {
                return `${TO_TEXT} ${splitThousands(this.incomeTo)} ${this.currency}`;
            }

            if (!this.incomeTo) {
                return `${FROM_TEXT} ${splitThousands(this.incomeFrom)} ${this.currency}`;
            }

            if (this.incomeFrom === this.incomeTo) {
                return `${splitThousands(this.incomeFrom)} ${this.currency}`;
            }

            return `${FROM_TEXT} ${splitThousands(this.incomeFrom)} – ${TO_TEXT} ${splitThousands(this.incomeTo)} ${this.currency}`;
        },

        tag() {
            if (!this.experience) {
                return NO_EXPERIENCE_LABEL;
            }

            if (this.experience === NO_EXPERIENCE_LABEL) {
                return this.experience;
            }

            return `${EXPERIENCE_TEXT} ${this.experience.toLowerCase()}`;
        },
    },
};
